// import logo from './actions/assets/images/logo.svg';
// import ReactDOM from "react-dom/client";
// import WebHeader from './actions/layouts/header';
import './actions/assets/styles/styles.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './actions/views/home';
import ProductPage from './actions/views/product';
import AboutUsPage from './actions/views/aboutUs';
import AOS from 'aos';
import { useEffect, useState } from 'react';

function App() {
  useEffect(() =>{
      AOS.init();
    },[]
  )
  const [scrolltopdata, setscrolltopdata] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0)
    
      window.addEventListener('scroll', () => {
          if (window.scrollY > 150) {
              setscrolltopdata('scrolled');
          } else {
              setscrolltopdata('');
          }
      });
  }, [])

  return (
    <div className={`App ${scrolltopdata}`}>
        <BrowserRouter>
          <Routes>
            <Route>
                <Route index element={<HomePage />} />
                <Route path="householdInsect-products" element={<ProductPage />} />
                <Route path="pestControl-product" element={<ProductPage />} />
                <Route path="agroChemical-product" element={<ProductPage />} />
                <Route path="products" element={<ProductPage />} />
                <Route path="about-us" element={<AboutUsPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;

import { Button, Container, Tab, Tabs, Row, Col, Card } from "react-bootstrap";
import {LuMoveRight} from 'react-icons/lu';
import VideoList from '../../server/video-list.json';
import { Player, BigPlayButton } from 'video-react';

function Blog() {
    return(
        <Container className="Blog-section section-space text-center">
            <h2 className='section-head-txt'> Diamond VIDEOS <br/> Mastery at Your Fingertips </h2>
            <p className='section-sub-head-txt'> Unlock Expert Tips for a Pest-Free Home </p>
            <Tabs
                defaultActiveKey="solution"
                id="uncontrolled-tab-example"
                className="mb-3"
                >
                {VideoList.VideoList.map((item, i) => (
                    <Tab eventKey={item.keyName} title={item.name} key={i}>
                        <Row>

                            {item.childs.map((child, i) => (
                                <Col md={3}  className="mb-3" key={i}>
                                    <Card className="video-card-ui">
                                        <Player
                                            playsInline
                                            poster={child.imageproduct}
                                            src={child.videoProduct}
                                        >
                                            <BigPlayButton position="center" />
                                        </Player>
                                        {/* <Card.Body>
                                            <Card.Text> {child.producttitle} </Card.Text>
                                        </Card.Body> */}
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Tab>
                ))}
            </Tabs>
            <Button className="viewMoreBtn"> View More <LuMoveRight/></Button>

        </Container>
    )
}
export default Blog;
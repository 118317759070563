import MultiProducts from '../../components/products-list';
import Footer from '../../layouts/footer';
import WebHeader from '../../layouts/header';

function ProductPage(){
    return(
       <>       
            <WebHeader/>
            <MultiProducts/>
            <Footer/>
       </>
    )
}

export default ProductPage;
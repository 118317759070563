import HeroHeader from '../../components/Hero-header'
import ChooseProduct from '../../components/Choose-Product'
import ExploreRange from '../../components/Explore-range';
import KnowEnemy from '../../components/know-enemy';
import Blog from '../../components/blog';
import Footer from '../../layouts/footer';
import WebHeader from '../../layouts/header';
function HomePage(){
    return(
       <>       
            <WebHeader/>
            <div className='homePage-ui'>
                <HeroHeader/>
                <ChooseProduct/>
                <ExploreRange/>
                <KnowEnemy/>
                <Blog/>
            </div>
            <Footer/>
       </>
    )
}

export default HomePage;
import { Container, Col, Tab, Nav, Row } from 'react-bootstrap';
import MosquitoeImg from "../../assets/images/product-details/Mosquitoe-norms.png";
import CockroacheImg from "../../assets/images/product-details/Cockroache-norms.png";
import FliesImg from "../../assets/images/product-details/flie-norms.png";
import AntsImg from "../../assets/images/product-details/Ant-norms.png";
import RatsImg from "../../assets/images/product-details/rate-norms.png";

import MobMosquitoeImg from "../../assets/images/product-details/Mosquitoe-norms-mob.png";
import MobCockroacheImg from "../../assets/images/product-details/Cockroache-norms-mob.png";
import MobFliesImg from "../../assets/images/product-details/flie-norms-mob.png";
import MobAntsImg from "../../assets/images/product-details/Ant-norms-mob.png";
import MobRatsImg from "../../assets/images/product-details/rate-norms-mob.png";

import {ReactComponent as NavMosquitoeImg} from "../../assets/images/mosquito.svg";
import {ReactComponent as NavCockroacheImg} from "../../assets/images/cockroaches.svg";
import {ReactComponent as NavFliesImg} from "../../assets/images/flies.svg";
import {ReactComponent as NavAntsImg }from "../../assets/images/ants.svg";
import {ReactComponent as NavRatsImg }from "../../assets/images/rate.svg";

function KnowEnemy(){
  return (
    <div className='knowEnemy-section section-space'>
        <Container>
            <h2 className='section-head-txt'> Understand Your Foes </h2>
            <p className='section-sub-head-txt'> Educate yourself on the pests invading your space and the illnesses they carry. </p>
            <Tab.Container id="left-tabs-example" defaultActiveKey="mosq">
                <Row>
                    <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="mosq">
                                    <img src={MosquitoeImg} alt='' className='w-100'/>
                                    <img src={MobMosquitoeImg} alt='' className='w-100 mobBannerImg'/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="cock">
                                    <img src={CockroacheImg} alt=''className='w-100'/>
                                    <img src={MobCockroacheImg} alt=''className='w-100 mobBannerImg'/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="flie">
                                    <img src={FliesImg} alt=''className='w-100'/>
                                    <img src={MobFliesImg} alt=''className='w-100 mobBannerImg'/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="ant">
                                    <img src={AntsImg} alt=''className='w-100'/>
                                    <img src={MobAntsImg} alt=''className='w-100 mobBannerImg'/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="rat">
                                    <img src={RatsImg} alt=''className='w-100'/>
                                    <img src={MobRatsImg} alt=''className='w-100 mobBannerImg'/>
                                </Tab.Pane>
                            </Tab.Content>
                    </Col>

                    <Col sm={12}>
                        <Nav variant="pills" className="justify-content-md-center justify-content-between knowEnemy-section-navBar">
                            <Nav.Item>
                                <Nav.Link eventKey="mosq"> 
                                    <NavMosquitoeImg/>
                                    Mosquitoes
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                
                                <Nav.Link eventKey="cock"> 
                                    <NavCockroacheImg/>
                                    Cockroaches
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="flie">
                                    <NavFliesImg/>
                                    Flies
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="ant">
                                    <NavAntsImg/>
                                    Ants
                                </Nav.Link>
                            </Nav.Item> 
                             <Nav.Item>
                                 
                                 <Nav.Link eventKey="rat">
                                    <NavRatsImg/>
                                    Rats
                                </Nav.Link>
                            </Nav.Item>                    
                        </Nav>
                    </Col>
                    
                </Row>
            </Tab.Container>
        </Container>
    </div>
  );
}

export default KnowEnemy;
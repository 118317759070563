import { Container, Row, Col, Card, Button, Tabs, Tab, ListGroup  } from "react-bootstrap";
import MultiProductsList from "../../server/product-list"
import HouseholdInsect from "../../server/product-list"
import PestControl from "../../server/product-list"
import chemicalProducts from "../../server/product-list"
import { PiShoppingCartFill } from "react-icons/pi";
import { useLocation } from "react-router-dom";
import BuyNow from "../modals/buy";
import { useState } from "react";
import { LuCheckCircle } from "react-icons/lu";


function MultiProducts() {

    const location = useLocation();
    const [showModal, setShowModal] = useState(false);

  return (
      <div className="multi-product-section Blog-section">
        <Container className="section-space text-center">
            <h2 className='section-head-txt mb-1 text-uppercase'>
                
               
                {location.pathname === "/householdInsect-products" ?
                    <> Household Pesticides </>
                    : location.pathname === "/pestControl-product" ?
                    <> Pest Control </>
                    :
                    <>  CHOOSE THE Right </>
                }
                PRODUCT </h2>
            <p className='section-sub-head-txt' > Use the appropriate product against specific pests. </p>
            <Row className="product-card-box">
            {location.pathname === "/householdInsect-products" ?
                <>
                    <ListGroup >
                        {HouseholdInsect.HouseholdInsect.map((item, i) => (
                        // <Col className="product-card-col" xs={6} md={4} key={i}>
                        //     <Card className="product-card" data-aos="fade-left" data-aos-duration="3000" >
                        //         <Card.Img variant="top" alt="100%x280"
                        //         src={item.imageproduct} />
                        //         <Card.Body>
                        //             <Card.Title className="mb-3"> {item.producttitle} </Card.Title>
                        //             <Button className="add-cart-btn" onClick={() => setShowModal(true)}> <PiShoppingCartFill/> BUY </Button>
                        //         </Card.Body>
                        //     </Card>
                        // </Col>
                              <ListGroup.Item> 
                                  <LuCheckCircle/> {item.producttitle} 
                              </ListGroup.Item>
                        ))}
                      </ListGroup>
                </>
                : location.pathname === "/pestControl-product" ?
                <>
                    <ListGroup >
                        {PestControl.PestControl.map((item, i) => (
                            <ListGroup.Item> 
                                <LuCheckCircle/> {item.producttitle} 
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                    {/* {PestControl.PestControl.map((item, i) => (
                        <Col className="product-card-col" xs={6} md={4} key={i}>
                            <Card className="product-card" data-aos="fade-left" data-aos-duration="3000" >
                                <Card.Img variant="top" alt="100%x280"
                                src={item.imageproduct} />
                                <Card.Body>
                                    <Card.Title className="mb-3"> {item.producttitle} </Card.Title>
                                    <Button className="add-cart-btn" onClick={() => setShowModal(true)}> <PiShoppingCartFill/> BUY </Button>
                                </Card.Body>
                            </Card>
                        </Col> */}
                </>
                : location.pathname === "/agroChemical-product" ?
                <>
                    <Tabs
                        defaultActiveKey="fumigant"
                        id="uncontrolled-tab-example"
                        className="mb-3 productList-tabs"
                        >
                        {chemicalProducts.chemicalProducts.map((item, i) => (
                            <Tab className="mt-0" eventKey={item.keyName} title={item.name} key={i}>
                                <Row className="productList-tabs-row">

                                    <ListGroup >
                                        {item.childs.map((child, i) => (
                                    //    <Col className="product-card-col" xs={6} md={3} key={i}>
                                    //         <Card className="product-card" data-aos="fade-left" data-aos-duration="3000" >
                                    //             <Card.Img variant="top" alt="100%x280"
                                    //             src={child.imageproduct} />
                                    //             <Card.Body>
                                    //                 <Card.Title className="mb-3"> {child.producttitle} </Card.Title>
                                    //                 <Button className="add-cart-btn" onClick={() => setShowModal(true)}> <PiShoppingCartFill/> BUY </Button>
                                    //             </Card.Body>
                                    //         </Card>
                                    //     </Col>

                                            <ListGroup.Item> 
                                                <LuCheckCircle/> {child.producttitle} 
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Row>
                            </Tab>
                        ))}
                    </Tabs>
                </>
                : location.pathname === "/products" ?
                <>
                    {MultiProductsList.MultiProductsList.map((item, i) => (
                        <Col className="product-card-col" xs={6} md={4} key={i}>
                            <Card className="product-card" data-aos="fade-left" data-aos-duration="3000" >
                                <Card.Img variant="top" alt="100%x280"
                                src={item.imageproduct} />
                                <Card.Body>
                                    <Card.Title className="mb-3"> {item.producttitle} </Card.Title>
                                    <div className="pricing">
                                        {/* <p>₹{item.sellingPrice}</p> */}
                                        {/* <p className="original-price">₹{item.originalPrice}</p>
                                        <p className="discount-badge">({item.discount}% OFF)</p> */}
                                    </div>
                                    <Button className="add-cart-btn" onClick={() => setShowModal(true)}> <PiShoppingCartFill/> BUY </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </>
                :
                <>
                </>
            }
            </Row>
        </Container>
        <BuyNow show={showModal} close={() => setShowModal(false)} />
    </div>
  );
}

export default MultiProducts;
import { Container, Nav, Navbar } from "react-bootstrap";
import CompanyLogo from "../../assets/images/logo.png";
import "../../assets/styles/header.scss";
import { BiLogoInstagramAlt, BiLogoFacebookSquare, BiLogoAmazon } from "react-icons/bi";
import { TfiYoutube } from "react-icons/tfi";
import { Outlet, Link } from "react-router-dom";

function WebHeader() {
  return (
    <Navbar expand="lg" className=" header-Ui py-0" sticky="top">
      <Container>
        <Link to="/" className="brand-logo">
          <img src={CompanyLogo} alt="" title="Diamond Products" />
        </Link>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav" className="header-navBar">
          <Nav className="social-link">
            <Container className="social-link-body">
              <Link className="nav-link" target="_blank" to="https://www.amazon.in/stores/DiamondProducts/Homepage/page/48697A5B-D051-48E0-AA77-81FF385130A2">
                {" "}
                <BiLogoAmazon />{" "}
              </Link>
              
              <Link className="nav-link" target="_blank" to="https://www.instagram.com/diamondproductsindia?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                {" "}
                <BiLogoInstagramAlt />{" "}
              </Link>              

              <Link className="nav-link" target="_blank" to="https://www.facebook.com/p/Diamond-Products-100064198176239/">
                <BiLogoFacebookSquare />
              </Link>
              
              <Nav.Link>
                {" "}
                <TfiYoutube />{" "}
              </Nav.Link>
            </Container>
          </Nav>

          <Nav className="ms-auto main-navBar">
            <Link to="/" className="nav-link" >Home</Link>
            <Link to="/agroChemical-product" className="nav-link" > Agro chemicals </Link>
            <Link to="/householdInsect-products" className="nav-link" > Household Pesticides </Link>
            <Link to="/pestControl-product" className="nav-link" > Pest Control </Link>
            <Link to="/about-us" className="nav-link" >About Us</Link>
          </Nav>
          <Outlet />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default WebHeader;

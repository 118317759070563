import { Container, Card, Row } from "react-bootstrap";
import RedHit from '../../assets/images/product/red-hit.png';
import BlackHit from '../../assets/images/product/black-hit.png';
import {LuMoveRight} from 'react-icons/lu';

function ChooseProduct() {
    return(
        <Container className="choose-product-section section-space">
            <h2 className='section-head-txt'> Say Goodbye to Pests with Our Hero Products </h2>
            {/* <p className='section-sub-head-txt'> Use the appropriate product against specific pests. </p> */}
            <Row className="choose-product-cards-grup">                
                <Card data-aos="fade-right" data-aos-duration="3000">
                    <Card.Img variant="top" src={BlackHit} />
                    <Card.Body className="left-card-body">
                        <Card.Title>Black Jallad</Card.Title>
                        <Card.Text>
                            Protection against mosquitoes in every small space and corner.                         
                        </Card.Text>
                        {/* <Card.Link href="#"> Know More <LuMoveRight/></Card.Link> */}
                    </Card.Body>
                </Card>
                <Card data-aos="fade-left" data-aos-duration="3000">
                    <Card.Body>
                        <Card.Title>Red Jallad</Card.Title>
                        <Card.Text>
                            Keep your home free from cockroaches throughout the year.                     
                        </Card.Text>
                        {/* <Card.Link href="#"> Know More <LuMoveRight/></Card.Link> */}
                    </Card.Body>
                    <Card.Img variant="top" src={RedHit} />
                </Card>
            </Row>
        </Container>
    )
}
export default ChooseProduct;
import { Figure, Container, Row, Col, ListGroup } from 'react-bootstrap';
import Footer from '../../layouts/footer';
import WebHeader from '../../layouts/header';
import CompanyAboutImage from '../../assets/images/company-image.png'
import {ReactComponent as CertifiedIcon} from '../../assets/images/certified.svg';
import { LuCheckCircle } from "react-icons/lu";
import { Link } from "react-router-dom";
import { LuMoveRight } from 'react-icons/lu';
import JourneyCounter from '../../components/journey-counter';

function AboutUsPage(){
    return(
       <>       
            <WebHeader/>
            <div className="about-us-section">
                <div className='about-us-header'>
                    <h2 className='section-head-txt mb-1'> About US </h2>
                </div>
                <Container className="section-space about-us-body">
                    <Row className='mb-2'>
                        <Col lg={6} sm={12}> 
                            <p>
                                In the late 1960s, in Indore, Mr. Shabbir Hussain Reshamwala started with just one insecticide. Today, our range has grown to over 60+effective solutions, from sprays to rodenticides, all centered around zinc phosphide and cypermethrin. Our commitment to innovation remains steadfast.
                            </p>
                            <p>
                            As we expanded beyond Indore, our focus on customer satisfaction grew too. Now, with pride, we serve over 250,000+ loyal customers who trust our brand. Their satisfaction speaks volumes. Our journey from one product to a comprehensive range reflects our dedication to excellence, making us the consistent choice for all insecticide needs.
                            </p>
                            <Figure>
                                <CertifiedIcon/>
                                <Figure.Caption>
                                    <h4> CERTIFIED PRODUCTS </h4>
                                    <p> We strictly follow the standards laid down by the Central Insecticide Board & Indian Standard Institution in our works. </p>
                                </Figure.Caption>
                            </Figure>
                        </Col>

                        <Col lg={{ span: 5, offset: 1 }} data-aos="fade-left" data-aos-duration="2000" className='about-us-body-img'>
                            <img src={CompanyAboutImage} alt='' title='' className='w-100'/>
                        </Col>
                    </Row>
                </Container>
                <div className='journeyUi'>
                    <Container className="section-space about-us-body">
                        <JourneyCounter/>
                    </Container>
                </div>
                <Container className="section-space about-us-body">
                    <div className='dealIn-section'>
                        <h3 className='sub-header'> We Deal With Various Quality Insecticide Products! </h3>
                        <ListGroup>
                            <ListGroup.Item> <LuCheckCircle/> Cockroach Control Products </ListGroup.Item>
                            <ListGroup.Item> <LuCheckCircle/> Bed bug Control Products </ListGroup.Item>
                            <ListGroup.Item> <LuCheckCircle/> Mosquito Control Products </ListGroup.Item>
                            <ListGroup.Item> <LuCheckCircle/> Ant Control Products </ListGroup.Item>
                            <ListGroup.Item> <LuCheckCircle/> Rodent Control Products </ListGroup.Item>
                            <ListGroup.Item> <LuCheckCircle/> Termite Control Products </ListGroup.Item>
                            <ListGroup.Item> <LuCheckCircle/> Flies Control Products </ListGroup.Item>
                            <ListGroup.Item> <LuCheckCircle/>  Agro chemicals </ListGroup.Item>
                            <ListGroup.Item> <LuCheckCircle/>  Household Pesticides  </ListGroup.Item>
                        </ListGroup>
                        <Link to="/products" className="btn viewMoreBtn"> View More <LuMoveRight/> </Link>
                    </div>
                </Container>
            </div>
            <Footer/>
       </>
    )
}

export default AboutUsPage;
import { Figure, Row } from "react-bootstrap";
// import ClientIcon from "../../assets/images/clients.svg"
// import ProductIcon from "../../assets/images/product-icon.svg"
// import CityIcon from "../../assets/images/city-icon.svg"
// import ExperienceIcon from "../../assets/images/award-icon.svg"
import { FaAward, FaBoxes } from "react-icons/fa";
import { TbBuildingStore, TbStarsFilled } from "react-icons/tb";
function JourneyCounter() {
  const counter = [
    {
      id: 1,
      icon: <FaAward />,
      title: "Loyal Customers",
      content: "25000",
    },
    {
      id: 2,
      icon: <FaBoxes/>,
      title: "Effective Soloutions",
      content: "60",
    },
    {
      id: 3,
      icon: <TbBuildingStore/>,
      title: "cities",
      content: "53",
    },
    {
      id: 4,
      icon: <TbStarsFilled/>,
      title: "Years in Exp",
      content: "73",
    },
  ];
  return (
    <Row>
      {counter.map((item) => (
        <Figure key={item.id} className="journeyBoxUi">
          {item.icon}
          <Figure.Caption>
            <h3>{item.content} +</h3>
            {item.title}
          </Figure.Caption>
        </Figure>
      ))}
    </Row>
  );
}

export default JourneyCounter;

import { Container, Carousel } from "react-bootstrap";
import '../../assets/styles/hero-banner.scss'

import SprayPestBanner from '../../assets/images/Banner-1.png'
import InsectPestBanner from '../../assets/images/Banner-3.png'
import CockroachPestBanner from '../../assets/images/Banner-2.png'

import MobSprayPestBanner from '../../assets/images/Banner-1-mobile.png'
import MobInsectPestBanner from '../../assets/images/Banner-3-mobile.png'
import MobCockroachPestBanner from '../../assets/images/Banner-2-mobile.png'

export default function HeroHeader() {
    return (
        <Container fluid className="hero-banner px-0" data-aos="fade-up"
        data-aos-duration="3000">
            <Carousel slide={false}>
                <Carousel.Item>
                    <img src={SprayPestBanner} alt=""/>
                    <img src={MobSprayPestBanner} className="mobBannerImg" alt=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={InsectPestBanner} alt=""/>
                    <img src={MobInsectPestBanner} className="mobBannerImg" alt=""/>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={CockroachPestBanner} alt=""/>
                    <img src={MobCockroachPestBanner} className="mobBannerImg" alt=""/>
                </Carousel.Item>
            </Carousel>
        </Container>
    )
}
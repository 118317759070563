import { Container, Carousel, Row, Col, Card  } from "react-bootstrap";
import ProductList from "../../server/product-list"
import MultiProductsList from "../../server/product-list"
import {LuMoveRight} from 'react-icons/lu';
import { Link } from "react-router-dom";


function ExploreRange() {
  return (
      <div className="explor-range">
        <Container className="choose-product-section section-space">
            <h2 className='section-head-txt'> Discover Our Products </h2>
            <Carousel slide={true} interval={5000} pause="hover" className="desktop-product-slider">
                {ProductList.ProductList.map((item, i) => (
                    <Carousel.Item key={i}>
                        <Row>
                            {item.childs.map((child, i) => (
                                <Col md={3}  className="mb-3" key={i}>
                                    <Card className="product-card">
                                        <Card.Img variant="top" alt="100%x280"
                                        src={child.imageproduct} />
                                        <Card.Body>
                                            <Card.Title> {child.producttitle} </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Carousel.Item>
                ))}    
            </Carousel>
            <Carousel slide={true} interval={5000} pause="hover" className="mob-product-slider">
                {MultiProductsList.MultiProductsList.map((item, i) => (
                    <Carousel.Item key={i}>
                        <Col md={3}  className="mb-3" key={i}>
                            <Card className="product-card">
                                <Card.Img variant="top" alt="100%x280"
                                src={item.imageproduct} />
                                <Card.Body>
                                    <Card.Title> {item.producttitle} </Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Carousel.Item>
                ))}    
            </Carousel>
            <Link to="products" className="btn viewMoreBtn"> View More <LuMoveRight/> </Link>
        </Container>
    </div>
  );
}
export default ExploreRange;

import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import "../../assets/styles/modal.scss"

const BuyNow = (props) => {

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.close}
        className='buyNow-modal'
        centered
        size="md"
    >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <img className='w-100' src="https://diamondproductsindia.com/assets/videos/thumbnail/Spot-Gel.png" alt='buy now from amazon' title=''/>
        </Modal.Body>
        <Modal.Footer>
          <Link className="btn viewMoreBtn" variant="secondary" target="_blank" to="https://www.amazon.in/stores/DiamondProducts/Homepage/page/48697A5B-D051-48E0-AA77-81FF385130A2">
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BuyNow;
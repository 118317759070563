import { Col, Container, Nav, Row, Stack, Form, Button } from "react-bootstrap";
// import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom'; 
import "../../assets/styles/footer.scss";
import { Outlet, Link } from "react-router-dom";
import { BiLogoInstagramAlt, BiLogoFacebookSquare, BiLogoAmazon } from "react-icons/bi";
import { TfiYoutube } from "react-icons/tfi";
import {ReactComponent as LocationIcon} from "../../assets/images/location.svg"
import {ReactComponent as HoursIcon} from "../../assets/images/time.svg"
// import {ReactComponent as TelephoneIcon} from "../../assets/images/telephone.svg"
import { FaPhone } from "react-icons/fa6";
import { RiMailSendFill } from "react-icons/ri";
import { RiContactsBookLine } from "react-icons/ri";

function Footer() {
    return(
        <> 
            <footer className="main-footer">
                <div className="footer-contact-frm" data-aos="fade-up">
                    <Container >
                        <Row className="align-items-center">
                            <Col lg={4} sm={12}>
                                <h3>
                                    Get Discount 30% Off
                                </h3>
                                <p>
                                    It Is A Long Established Fact That A Reader Will Be Distracted By The Readable
                                </p>
                            </Col>
                            <Col lg={{ span: 7, offset: 1 }} sm={12}>
                                <Stack direction="horizontal" gap={3}>
                                    <Form.Control className="me-auto" placeholder="enter your email addres" />
                                    <Button className="submit-email-btn">Submit</Button>
                                </Stack>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="main-footer-upper">
                    <Container>
                        <Row>
                            <Col sm={6} lg={4} className="text-left footer-upper-col">
                                <h4> <LocationIcon className="red"/> Address</h4>
                                <p>84, Ranipura Main Rd, Siyaganj, <br/>Indore, Madhya Pradesh 452007</p>
                            </Col>
                            <Col sm={6} lg={4} className="text-center footer-upper-col">
                                <h4><HoursIcon/> Opening Hours</h4>
                                <p>Mon - Sat,8am to 5pm, <br/>
                                    Closed Sundays    
                                </p>
                            </Col>
                            <Col sm={6} lg={4} className="text-right footer-upper-col">
                                <h4>
                                    {/* <TelephoneIcon/>  */}
                                    <RiContactsBookLine /> Contact </h4>
                                <p>     <FaPhone /> <a href="tel:+91 22 23441795"> +91 22 23441795</a> , <a href="tel:+91 75092 60504"> +91 75092 60504</a> </p>     
                                <p>     <RiMailSendFill /> <a href="mailto:diamondproducts786@gmail.com">diamondproducts786@gmail.com</a> </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="main-footer-down">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <Col sm={12} className="d-flex flex-wrap">
                                    <Nav className="me-auto rtl-footer-navBar">
                                        <p>
                                            © 2023 Diamond Product. All rights reserved.
                                        </p>
                                    </Nav>
                                    <Nav className="ms-md-auto footer-navBar">
                                        <Link target="_blank" to="https://www.amazon.in/stores/DiamondProducts/Homepage/page/48697A5B-D051-48E0-AA77-81FF385130A2">
                                            <BiLogoAmazon />
                                        </Link>
                                        <Link target="_blank" to="https://www.instagram.com/diamondproductsindia?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                                            <BiLogoInstagramAlt />
                                        </Link>
                                        <Link target="_blank" to="https://www.facebook.com/p/Diamond-Products-100064198176239/">
                                            <BiLogoFacebookSquare />
                                        </Link>
                                        <Link to="/">
                                            <TfiYoutube />
                                        </Link>
                                    </Nav>
                                    <Outlet />
                                </Col>
                            </Col>
                        </Row>    
                    </Container>
                </div>              
            </footer>
        </>
    )
}
export default Footer;